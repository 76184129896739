<script setup lang="ts">
const { t } = useI18n();
const user = useUser();
</script>

<template>
  <div class="outside-layout bg-neutral-light-50 dark:bg-neutral-dark-900 min-h-screen">
    <!-- Blue background -->
    <div class="bg-[#233C5F] absolute top-0 left-0 w-full h-[320px] z-10" />

    <AuthHeader class="relative z-30 py-6 pxl-container">
      <div
        v-if="!user"
        id="header-actions"
      >
        <UButton
          size="sm"
          name="sign-up"
          class="border border-white text-white bg-transparent"
          :to="{ path: '/auth/sign-up/', query: { redirectUrl: $route.fullPath } }"
          :label="t('labels.sign_up')"
        />
      </div>
    </AuthHeader>

    <div class="relative z-20 py-12 pxl-container">
      <div class="pxl-block max-w-[880px] w-full mx-auto md:py-[4.5rem]">
        <NuxtPage />
      </div>
    </div>
  </div>
</template>
